import React from 'react';
import { NextSeo } from 'next-seo';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { redirectToUnderConstructionPage } from '@/utils/rewrites';
import ErrorSection from '@/sections/error';
import { Layout, ErrorLayout } from '@/components/templates';
import { NextPageWithLayout } from '@/types';

const Error404Page: NextPageWithLayout = () => {
    const { t } = useTranslation(); // Translations

    return (
        <>
            <NextSeo title={t('seo:error.404.title')} />

            <ErrorSection statusCode={404} />
        </>
    );
};

Error404Page.getLayout = (page: React.ReactElement) => {
    return (
        <Layout>
            <ErrorLayout>{page}</ErrorLayout>
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async (context) => {
    const rules = redirectToUnderConstructionPage();

    const translations = await serverSideTranslations(context?.locale as string, ['common', 'seo', 'error']);

    return {
        ...rules,
        props: {
            ...translations,
        },
    };
};

export default Error404Page;
