import React from 'react';
import { useTranslation } from 'next-i18next';
import Error403SVG from '@/assets/images/errors/403.svg';
import Error404SVG from '@/assets/images/errors/404.svg';
import Error500SVG from '@/assets/images/errors/500.svg';
import { Section } from '@/components/molecules';
import { Error } from '@/components/organisms';

interface ErrorDetail {
    title: string;
    image: React.ReactElement;
    subtitle1: string;
    subtitle2: string;
}
const ErrorSection: React.FC<{
    statusCode: number;
}> = ({ statusCode }) => {
    const { t } = useTranslation(); // Translations

    const errors: Record<number, ErrorDetail> = {
        403: {
            title: t('error:403.title'),
            image: <Error403SVG height="150px" />,
            subtitle1: t('error:403.subtitle'),
            subtitle2: t('error:403.subtitle2'),
        },
        404: {
            title: t('error:404.title'),
            image: <Error404SVG height="150px" />,
            subtitle1: t('error:404.subtitle'),
            subtitle2: t('error:404.subtitle2'),
        },
        500: {
            title: t('error:500.title'),
            image: <Error500SVG height="150px" />,
            subtitle1: t('error:500.subtitle'),
            subtitle2: t('error:500.subtitle2'),
        },
    };

    return (
        <Section id={`error-${statusCode}`} container="fluid">
            <Error {...errors[statusCode]} />
        </Section>
    );
};

export default ErrorSection;
